import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-richmond-california.png'
import image0 from "../../images/cities/scale-model-of-ecohouse-in-richmond-california.png"
import image1 from "../../images/cities/scale-model-of-the-campanile-in-richmond-california.png"
import image2 from "../../images/cities/scale-model-of-roof-of-berkeley-(baam-e-berkeley)-in-richmond-california.png"
import image3 from "../../images/cities/scale-model-of-shorebird-park-nature-center-in-richmond-california.png"
import image4 from "../../images/cities/scale-model-of-ocean-view-park-in-richmond-california.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Richmond'
            state='California'
            image={image}
            lat='37.9357576'
            lon='-122.34774859999999'
            attractions={ [{"name": "EcoHouse", "vicinity": "1305 Hopkins St, Berkeley", "types": ["point_of_interest", "establishment"], "lat": 37.878482, "lng": -122.28844500000002}, {"name": "The Campanile", "vicinity": "Sather Tower, Berkeley", "types": ["point_of_interest", "establishment"], "lat": 37.87206159999999, "lng": -122.25781230000001}, {"name": "Roof of Berkeley (Baam-E-Berkeley)", "vicinity": "Berkeley", "types": ["point_of_interest", "establishment"], "lat": 37.8806149, "lng": -122.24779969999997}, {"name": "Shorebird Park Nature Center", "vicinity": "160 University Ave, Berkeley", "types": ["park", "point_of_interest", "establishment"], "lat": 37.8630644, "lng": -122.3156222}, {"name": "Ocean View Park", "vicinity": "900 Buchanan St, Albany", "types": ["park", "point_of_interest", "establishment"], "lat": 37.8868471, "lng": -122.30260190000001}] }
            attractionImages={ {"EcoHouse":image0,"The Campanile":image1,"Roof of Berkeley (Baam-E-Berkeley)":image2,"Shorebird Park Nature Center":image3,"Ocean View Park":image4,} }
       />);
  }
}